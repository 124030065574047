import React, { useState, useMemo, useEffect } from 'react';
import { arrayMoveImmutable } from 'array-move';
import { v4 as uuidv4 } from 'uuid';
import TemplateDialog from 'components/ui-base/TemplateDialog';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import Templates, { withTemplates } from 'components/data/Templates';
import User from 'components/data/User';
import Translation from 'components/data/Translation';
import EditorData from 'components/editor-data/EditorData';
import VisualStackEditorHelpers from 'components/data/VisualStackEditorHelpers';
import VisualStackEditor, { VisualStackEditorToolbox } from 'components/ui-base/VisualStackEditor';
import cloneDeep from 'helpers/cloneDeep';
import Illustration from 'components/ui-components-cape/Illustration';
import EmptyState from 'components/ui-components-cape/EmptyState';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { isAMV2Enabled } from 'components/template-management/utilities';
import AssetGalleryDialog from 'components/assets/AssetGalleryDialog';
import { convertAssetV2toTemplate } from 'components/data/Templates/helpers/template-converter';
import Setup from 'components/data/Setup';
import { exportVisualStackBlockToCreativeManagement } from 'components/creative-management-v2/utilities';
import { LandingpagePreviewRender, LandingpagePreviewViewSwitch } from '../../LandingpagePreview';
import LandingPageEditorRenderOverlay from './render-overlay';

import '../styles/main.scss';

/**
 * Return an updated order of the landing page blocks as stored in value.
 * @param {array} value landing page blocks in their current order.
 * @param {string} sourceIndex index of the landing page block being dragged.
 * @param {string} targetIndex index of the landing page block being dragged over.
 */
const setValueOrder = (value, sourceIndex, targetIndex) => {
    const newValue = arrayMoveImmutable(value, sourceIndex, targetIndex);
    return newValue;
};

/**
 * Create an array of blocks to populate the add block dialog.
 * @param {number} userLevel
 * @param {object} landingpageBlocks
 * @returns {array}
 */
const setupAddOptions = (userLevel, landingpageBlocks) => {
    if (isAMV2Enabled()) return null;
    const addOptions = Object.keys(landingpageBlocks)
        .map((key) => landingpageBlocks[key])
        .filter((item) => {
            const settings = item.settings ? item.settings : {};
            if (!('levelCanAdd' in settings)) {
                return true;
            } else if (settings.levelCanAdd <= userLevel) {
                return true;
            } else {
                return false;
            }
        });
    return addOptions;
};

// Get the templateProps for the AMV2 based TemplateDialog.
const getTemplateProps = (groupKey) => {
    if (!isAMV2Enabled()) return null;

    const dataFilters = {};

    dataFilters['settings.levelCanAdd'] = `<=${User.getLevel()}`;

    if (groupKey) dataFilters.groupKey = groupKey;

    return { subType: 'landingpageBlock', dataFilters };
};

const getCreativeProps = (groupKey) => {
    if (!isAMV2Enabled('creative')) return null;
    if (!Setup.hasModule('creativeManagement')) return null;
    return getTemplateProps(groupKey);
};

/**
 * Create an array of categorie for the add block dialog.
 * @param {object} landingpageBlocks
 * @returns {array}
 */
const setupAddCategories = (landingpageBlocks) => {
    if (isAMV2Enabled()) return null;

    const addCategories = [];
    Object.keys(landingpageBlocks).forEach((key) => {
        const thisCat = landingpageBlocks[key].category ? landingpageBlocks[key].category.toLowerCase() : false;
        if (thisCat && thisCat.length > 0 && addCategories.indexOf(thisCat) < 0) {
            addCategories.push(thisCat);
        }
    });
    return addCategories;
};

/**
 * Get a working title for a newly created landing page block based on it's template title and a version number.
 * @param {string} title Title of the template the block is based on.
 * @returns {string} working title
 */
const getWorkingTitle = (title, value) => {
    let version = 1;
    let workingTitle = `${title} - ${version}`;

    if (!value) return workingTitle;

    while (value.findIndex((block) => block.workingTitle === workingTitle) >= 0) {
        version++;
        workingTitle = `${title} - ${version}`;
    }

    return workingTitle;
};

/**
 * Get the permission for one feature of a landing page block based on the settings of the template block.
 * @param {string} feature The feauture being checked.
 * @param {object} settings The settings as set in the template block.
 * @param {number} userLevel level of the current user.
 * @param {boolean} orgSetting The start setting for the feature being examened before this function is called.
 * @returns {boolean}
 */
const getPermission = (feature, settings, userLevel, orgSetting) => {
    if (!(feature in settings)) {
        return orgSetting;
    } else if (settings[feature] <= userLevel) {
        return true;
    } else {
        return false;
    }
};

/**
 * Filter the landingpage blocks by groupKey.
 * @param {string} groupKey
 * @returns {object}
 */
const getLandingPageBlocks = (groupKey) => {
    const startBlocks = Templates.get('landingpageBlock');
    if (!startBlocks) return {};
    if (!groupKey) return startBlocks;
    const landingpageBlocks = {};
    Object.keys(startBlocks).forEach((key) => {
        const block = Templates.get('landingpageBlock', key);
        if (block.groupKey === groupKey) {
            landingpageBlocks[key] = block;
        }
    });

    return landingpageBlocks;
};

/**
 * Display the landing page editor
 * @param {object} props
 * @returns
 */
const LandingpageEditor = (props) => {
    const {
        value,
        model,
        dataModel,
        sourceData,
        settingsModel = 'landingpage.settings',
        editor,
        originSelector,
        previewUrl,
        canEdit = true, // Global permission defined in campaignFormat
        canEditAB = true, // Global permission defined in campaignFormat
        canMove = true, // Global permission defined in campaignFormat
        canRemove = true, // Global permission defined in campaignFormat
        canAdd = true, // Global permission defined in campaignFormat
        maxBlocks,
        subsets,
        onClose,
        onMutation,
        groupKey
    } = props;

    const landingpageBlocks = useMemo(() => getLandingPageBlocks(groupKey), []);
    const userLevel = useMemo(() => parseInt(User.getLevel(), []));
    const abTesting = useMemo(() => VisualStackEditorHelpers.getAbTestSetting(settingsModel), [settingsModel]);
    // Add options and categories are only used in the old, ContentSpace based TemplateDialog
    const addOptions = useMemo(() => setupAddOptions(userLevel, landingpageBlocks), []);
    const addCategories = useMemo(() => setupAddCategories(landingpageBlocks), []);
    // templateProps are used in the new, AMV2 based TemplateDialog
    const templateProps = useMemo(() => getTemplateProps(groupKey), []);
    const creativeProps = useMemo(() => getCreativeProps(groupKey), []);

    const [editingItem, setEditingItem] = useState(
        value && value.length ? VisualStackEditorHelpers.getEditingItem(value[0].uuid, value, landingpageBlocks) : null
    );
    const [abTestGroupsShown, setAbTestGroupsShown] = useState(abTesting ? [...abTesting] : false);
    const [viewWidth, setViewWidth] = useState(1250);
    const [layout, setLayout] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [blockToDelete, setBlockToDelete] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(null);
    const [canMoveCalculated, setCanMoveCalculated] = useState(false);
    const [canRemoveCalculated, setCanRemoveCalculated] = useState(false);
    const [canAddCalculated, setCanAddCalculated] = useState(false);

    useEffect(() => {
        setMounted(true);
        // We want to be sure ab testing is setup according to the latest settings.
        VisualStackEditorHelpers.checkAbTesting(value, abTesting, dataModel);
        // Set the permissions for the initial active block.
        if (editingItem && editingItem.uuid) setPermissions(editingItem.uuid, value);
    }, []);

    useEffect(() => {
        if (editingItem && abTesting && abTestGroupsShown.length === 1) {
            if (layout.length === 0) {
                setEditingItem(null);
            } else {
                const found = layout.find((v) => v.uuid === editingItem.uuid);
                if (!found) {
                    const uuid = layout[0].uuid;
                    setEditingItem(VisualStackEditorHelpers.getEditingItem(uuid, value, landingpageBlocks));
                    setPermissions(uuid, value);
                }
            }
        }
    }, [layout]);

    /**
     * Determine the permissions of a landing page block.
     * @param {string} uuid id of the landing page block
     * @param {array} value configured landing page
     * @param {number} userLevel level of the current user
     * @returns {object} The returned object contains boolean keys canMove, canRemove and canAdd
     */
    const setPermissions = (uuid, value) => {
        // Check whether maximum number of blocks is reached based on maxBlocks key in campaignFormat
        const maxBlocksReached = maxBlocks && value.length >= maxBlocks;

        if (uuid) {
            // Get block settings
            const thisValue = value.find((v) => {
                return v.uuid === uuid;
            });
            const landingpageBlock = Templates.get('landingpageBlock', thisValue.identifier);
            const settings = landingpageBlock && landingpageBlock.settings ? landingpageBlock.settings : {};

            // Determine rights on block settings / template level
            const canMoveBlock = getPermission('levelCanMove', settings, userLevel, canMove);
            const canRemoveBlock = getPermission('levelCanRemove', settings, userLevel, canRemove);

            // Return derived permissions object to be used in component state.
            setCanMoveCalculated(canMoveBlock);
            setCanRemoveCalculated(canRemoveBlock);
            setCanAddCalculated(canAdd && !maxBlocksReached); // Can add is always a global setting provided maxBlocks isn't reached.
        } else {
            setCanMoveCalculated(false);
            setCanRemoveCalculated(false);
            setCanAddCalculated(canAdd && !maxBlocksReached); // Can add is always a global setting provided maxBlocks isn't reached.
        }
    };

    /**
     * Set the active block, the block being edited.
     * If scroll is true, scroll it into view.
     * @param {string} uuid
     * @param {boolean} scroll
     */
    const setActive = (uuid, scroll) => {
        const editingItem = VisualStackEditorHelpers.getEditingItem(uuid, value, landingpageBlocks);
        setEditingItem(editingItem);
        setPermissions(uuid, value);

        if (scroll) {
            const layoutItem = layout.find((x) => x.uuid === uuid);
            if (layoutItem) {
                setScrollPosition(layoutItem.y);
            }
        }
    };

    /**
     * Stop editing, no block is active.
     */
    const closeEdit = () => {
        setEditingItem(null);
        setPermissions(null, value);
    };

    /**
     * Move the top of the floating toolbox to a certain y position on the page.
     * @param {number} top
     */
    const onMoveToolbox = (top) => {
        if (document.getElementById('visual-stack-editor-toolbox')) {
            document.getElementById('visual-stack-editor-toolbox').style.top = `${top}px`;
        }
    };

    /**
     * Move the editingtem  one place up or one place down.
     * @param {number} offset
     */
    const onMoveBlockKeys = (offset) => {
        if (!editingItem) return false;
        const currentIndex = value.findIndex((x) => x.uuid === editingItem.uuid);
        const newIndex = currentIndex + offset;

        if (newIndex >= 0 && newIndex <= value.length - 1) {
            const newValue = setValueOrder(
                value,
                value.findIndex((x) => x.uuid === activeBlock),
                currentIndex + offset
            );
            onMutation(newValue);
        }
    };

    /**
     * Set the AB testgroup to show.
     * @param {string} value
     */
    const onSetAbTestGroupsShown = (value) => {
        setAbTestGroupsShown(value);
    };

    /**
     * Update the value if a block is added.
     * @param {string} uuid
     * @param {string} identifier
     * @param {string} title
     */
    const setNewValueOnAdd = (uuid, identifier, title) => {
        const newValue = value ? [...value] : [];
        let indexToAdd = 0;
        if (editingItem && editingItem.uuid) {
            indexToAdd = value.findIndex((x) => x.uuid === editingItem.uuid) + 1;
        }
        newValue.splice(indexToAdd, 0, {
            uuid,
            workingTitle: getWorkingTitle(title, value),
            identifier
        });
        onMutation(newValue);
        setEditingItem(VisualStackEditorHelpers.getEditingItem(uuid, newValue, landingpageBlocks));
        setPermissions(uuid, newValue);
    };

    /**
     * Set the data of a new block.
     * @param {string} uuid
     * @param {object} blockData
     */
    const setNewDataOnAdd = (uuid, blockData) => {
        if (blockData) {
            if (subsets) {
                EditorData.setModel(`${dataModel}.${uuid}.subsetData.main`, blockData);
            } else {
                EditorData.setModel(`${dataModel}.${uuid}`, blockData);
            }
        }
    };

    /**
     * Add a new block to the stack from a template.
     * @param {object} item
     */
    const handleAddItem = (item) => {
        if (isAMV2Enabled()) {
            item = convertAssetV2toTemplate(item);
        }
        const uuid = 'block_' + uuidv4().replace(/-/g, '');
        // Set default data
        setNewDataOnAdd(uuid, item.defaultData);
        setNewValueOnAdd(uuid, item.identifier, item.title);
        setAddDialogOpen(false);
    };

    /**
     * Add a new block to the stack from a creative.
     * @param {object} item
     */
    const handleAddCreative = (item) => {
        const { setup, blocks } = item.data.templateInput;
        const storedUuid = setup[0].uuid;
        const uuid = 'block_' + uuidv4().replace(/-/g, '');
        const blockData = blocks[storedUuid] ? cloneDeep(blocks[storedUuid]) : null;
        setNewDataOnAdd(uuid, blockData);
        setNewValueOnAdd(uuid, item.data.templateIdentifier, item.title);
        setAddDialogOpen(false);
    };

    /**
     * Copies the active item
     */
    const copyItem = () => {
        if (!editingItem.uuid || !editingItem.identifier) {
            SnackbarUtils.error(Translation.get('landingPage.errors.copyingLandingPage', 'editor'));
            return;
        }

        const uuid = 'block_' + uuidv4().replace(/-/g, '');

        //Set the data of the copied item
        const itemToCopy = cloneDeep(EditorData.getValueFromModel(`${dataModel}.${editingItem.uuid}`));
        EditorData.setModel(`${dataModel}.${uuid}`, itemToCopy);

        //Set the setup of the item
        const newValue = value ? [...value] : [];
        const template = landingpageBlocks[editingItem.identifier];

        const indexToAdd = value.findIndex((x) => x.uuid === editingItem.uuid) + 1;
        newValue.splice(indexToAdd, 0, {
            uuid,
            workingTitle: getWorkingTitle(template.title, value),
            identifier: template.identifier
        });
        onMutation(newValue);

        //Set editing / active item
        setEditingItem(VisualStackEditorHelpers.getEditingItem(uuid, newValue, landingpageBlocks));
        setPermissions(uuid, newValue);
        setAddDialogOpen(false);
    };

    const handleExportToCreativeManagement = async () => {
        await exportVisualStackBlockToCreativeManagement('landingpageBlock', dataModel, editingItem, subsets, groupKey);
    };

    /**
     * Remove a block from the stack.
     */
    const deleteConfirmed = () => {
        const newValue = [...value].filter((x) => x.uuid !== blockToDelete);
        onMutation(newValue);
        const newUuid = newValue.length > 0 ? newValue[0].uuid : false;
        setEditingItem(VisualStackEditorHelpers.getEditingItem(newUuid, newValue, landingpageBlocks));
        if (newUuid) {
            setPermissions(newUuid, newValue);
        }
        setBlockToDelete(null);
    };

    const activeBlock = editingItem ? editingItem.uuid : null;

    return (
        <React.Fragment>
            <VisualStackEditor
                templateType="landingpageBlock"
                value={value}
                dataModel={dataModel}
                editingItem={editingItem}
                subsets={subsets}
                language={editor && editor.language}
                title={Translation.get('visualStackEditor.title.landingpage', 'editor')}
                abTesting={abTesting}
                abTestGroupsShown={abTestGroupsShown}
                originSelector={originSelector}
                templates={landingpageBlocks}
                canEdit={canEdit}
                canEditAB={canEditAB}
                scrollPosition={scrollPosition}
                onClose={onClose}
                onSetAbTestGroupsShown={(value) => onSetAbTestGroupsShown(value)}
                onSetActive={setActive}
                onCloseEdit={closeEdit}>
                <div className="landingpage-editor">
                    {value && value.length > 0 ? (
                        <React.Fragment>
                            <div className="landingpage-editor__actions">
                                <LandingpagePreviewViewSwitch viewWidth={viewWidth} onSetViewWidth={setViewWidth} />
                            </div>
                            <div className="landingpage-editor__content">
                                {mounted && (
                                    <div className="landingpage-editor__content-area" style={{ maxWidth: viewWidth }}>
                                        {activeBlock && (
                                            <VisualStackEditorToolbox
                                                className="landingpage-editor__content-area__toolbox"
                                                onDelete={() => setBlockToDelete(activeBlock)}
                                                onMoveBlockKeys={onMoveBlockKeys}
                                                onOpenAddBlockDialog={() => setAddDialogOpen(true)}
                                                activeBlock={activeBlock}
                                                activeFirst={value.length > 0 && value[0].uuid === activeBlock}
                                                activeLast={value.length > 0 && value[value.length - 1].uuid === activeBlock}
                                                canEdit={canEdit}
                                                canMove={canMoveCalculated}
                                                canRemove={canRemoveCalculated}
                                                canAdd={canAddCalculated}
                                                onCopy={() => copyItem()}
                                                onExportToCreativeManagement={handleExportToCreativeManagement}
                                            />
                                        )}
                                        <LandingpagePreviewRender
                                            key="lpr-edit"
                                            value={value}
                                            sourceData={sourceData}
                                            iFrameUrl={previewUrl}
                                            iFrameWidth={viewWidth}
                                            language={EditorData.getLanguage()}
                                            abTesting={abTesting}
                                            abTestGroupsShown={abTestGroupsShown}
                                            model={model}
                                            dataModel={dataModel}
                                            settingsModel={settingsModel}
                                            onLayoutChange={setLayout}
                                        />
                                        {activeBlock && (
                                            <LandingPageEditorRenderOverlay
                                                layout={layout}
                                                activeBlock={activeBlock}
                                                onSetActive={setActive}
                                                onMoveToolbox={onMoveToolbox}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    ) : (
                        <EmptyState
                            className="landingpage-editor__empty-state"
                            illustration={<Illustration illustration="fallback" />}
                            primaryText={Translation.get('visualStackEditor.placeholder.title', 'editor')}
                            secondaryText={Translation.get('visualStackEditor.placeholder.subtitle', 'editor')}
                            primaryButton={{ label: Translation.get('visualStackEditor.placeholder.action', 'editor'), onClick: () => setAddDialogOpen(true) }}
                        />
                    )}
                </div>
            </VisualStackEditor>

            {addOptions && (
                // This is the old, on ContentSpace based TemplateDialog
                <TemplateDialog
                    isOpen={addDialogOpen}
                    options={addOptions}
                    hideCreatives
                    categories={addCategories}
                    onClose={() => setAddDialogOpen(false)}
                    onSelectItem={handleAddItem}
                />
            )}

            {templateProps && (
                // This is the AMV2 based TemplateDialog
                <AssetGalleryDialog
                    title={Translation.get('formflow.JSON.addItem', 'common')}
                    onMutation={() => null} // onMutation is not used in the new temlate selector.
                    open={addDialogOpen}
                    fixedHeightPaperScrollPaper
                    onClose={() => setAddDialogOpen(false)}
                    selectors={creativeProps ? ['template', 'creative'] : ['template']}
                    selectorsProps={{
                        templateProps: { onSelectItem: handleAddItem, ...templateProps },
                        creativeProps: creativeProps ? { onSelectItem: handleAddCreative, ...creativeProps } : null
                    }}
                />
            )}

            <ConfirmDialog
                open={!!blockToDelete}
                title={Translation.get('visualStackEditor.actions.deleteBlockTitle', 'editor')}
                description={Translation.get('visualStackEditor.actions.deleteBlockDescription', 'editor')}
                onConfirm={deleteConfirmed}
                onClose={() => setBlockToDelete(null)}
            />
        </React.Fragment>
    );
};

export default withTemplates(LandingpageEditor, [{ type: 'landingpageBlock', fullData: true }]);
